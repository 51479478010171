#home-banner {
    height: calc(var(--ovh, 1vh)*100);
    background: rgb(132, 89, 255);
    display: flex;
    place-content: center;
    align-items: center;
    overflow: hidden;
    position: relative;
    top: 0px;
    left: 0px;
    width: 100%;
    padding: 0px;
    z-index: 4;
    user-select: none;
}